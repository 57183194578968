import { useLocation } from "react-router-dom";
import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import iphoneGroupImage from "../../../assets/images/iphone-group.png";
import qrCodeImage from "../../../assets/images/qr-code.png";
import routes from "../../../router/routes";

const routesWithAdd = [routes.HOME, routes.RESULTS];

const DownloadAppAd = () => {
  const { pathname } = useLocation();

  if (!routesWithAdd.includes(pathname)) {
    return null;
  }

  return (
    <Stack bgcolor="#FAFBFF">
      <Container>
        <Stack
          minHeight="100vh"
          flexDirection="row"
          gap={{ xs: 4, md: 8 }}
          alignItems="center"
        >
          <Stack gap={4}>
            <Stack gap={1}>
              <Typography
                fontWeight={700}
                color="primary.main"
                textTransform="uppercase"
                variant="body2"
                sx={{ cursor: "pointer" }}
              >
                Download the app
              </Typography>
              <Typography variant="h2">
                Unlock hidden movie gems with Tasma app
              </Typography>
            </Stack>
            <Paper sx={{ borderRadius: 2 }} elevation={5}>
              <Stack gap={3} alignItems="center" flexDirection="row" p={2}>
                <Box minWidth="100px">
                  <img src={qrCodeImage} alt="qr code" />
                </Box>
                <Stack gap={2}>
                  <Typography color="text.secondary" variant="body2">
                    Use your phone's camera to scan and download the free Tasma
                    app
                  </Typography>
                  <Typography fontWeight={700} variant="body2">
                    Available on{" "}
                    <Typography
                      fontWeight="inherit"
                      component="span"
                      color="primary.main"
                      sx={{ cursor: "pointer" }}
                    >
                      iOS
                    </Typography>{" "}
                    and{" "}
                    <Typography
                      fontWeight="inherit"
                      component="span"
                      color="primary.main"
                      sx={{ cursor: "pointer" }}
                    >
                      Android
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
          <Box display={{ xs: "none", sm: "block" }}>
            <img
              width="auto"
              src={iphoneGroupImage}
              alt="iphone with tasma app"
            />
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default DownloadAppAd;
