import Card from "./Card";
import Paper from "./Paper";
import Button from "./Button";
import Backdrop from "./Backdrop";
import Chip from "./Chip";

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Card(theme),
    Paper(theme),
    Button(theme),
    Backdrop(theme),
    Chip(theme)
  );
}
