import React from "react";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./react-query/queryClient";
import ThemeProvider from "./theme";
import Router from "./router";
import LoadingScreen from "./components/LoadingScreen";
import { ModalContextProvider } from "./context/modals/modalContext";
import NavigateHome from "./components/NavigateHome";

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ModalContextProvider>
          <NavigateHome />
          <LoadingScreen />
          <Router />
          <ReactQueryDevtools />
          <ToastContainer />
        </ModalContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
