import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useQuery, useQueryClient } from "react-query";
import { queryKeys } from "../react-query/constants";
import BottomNavigation from "../components/BottomNavigation/BottomNavigation";
import VideoProcessModal from "../modals/VideoProcessModal";
import { useModalContext } from "../context/modals/modalContext";
import { modals } from "../context/modals";
import { findMovieBySearch } from "../api";
import convertSecondsToTime from "../utils/convertSecondsToTime";
import routes from "../router/routes";
import Player from "../components/Player/Player";
import PlayerControls from "../components/Player/PlayerControls";

const MAX_LENGTH = 300;

const SettingsPage = () => {
  const [player, setPlayer] = useState(null);
  const [value, setValue] = useState([0, 100]);

  const { setActiveModal } = useModalContext();

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { data: url } = useQuery([queryKeys.settings]);

  const onResumeClick = () => {
    if (player?.activeThumb === 1) {
      player.currentTime = (player.duration / 100) * value[0];
    }

    if (player.paused) {
      player.play();
    } else {
      player.pause();
    }
  };

  const handleChange = (event, newValue, activeThumb) => {
    player.pause();

    if (!Array.isArray(newValue)) {
      return;
    }

    player.currentTime = (player.duration / 100) * newValue[activeThumb];
    player.activeThumb = activeThumb;

    setValue(newValue);
  };

  const onCancel = () => {
    setValue([0, 100]);
  };

  const continueToResultsPage = async () => {
    queryClient.removeQueries([queryKeys.results]);
    await queryClient.fetchQuery([queryKeys.results], () =>
      findMovieBySearch({
        url: url.config.url.slice(21),
        startTime: convertSecondsToTime((player.duration / 100) * value[0]),
        endTime: convertSecondsToTime((player.duration / 100) * value[1]),
      })
    );

    navigate(routes.RESULTS);
  };

  const onContinue = () => {
    const trimmedVideoLength = (player.duration * (value[1] - value[0])) / 100;

    console.log('trimmedVideoLength', trimmedVideoLength)

    if (trimmedVideoLength > MAX_LENGTH) {
      setActiveModal(modals.VIDEO_PROCESS_MODAL);
    } else {
      continueToResultsPage();
    }
  };

  if (!url) {
    return null;
  }

  return (
    <>
      <VideoProcessModal onContinue={continueToResultsPage} />
      <Container>
        <Stack gap={2}>
          <Button
            onClick={() => navigate(routes.HOME)}
            sx={{ width: "max-content", color: "text.secondary" }}
          >
            <ArrowBackIosIcon /> Back
          </Button>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3">Select movie episode to search</Typography>
          </Stack>
          <Stack flexDirection="column" gap={2}>
            <Stack flex={5} gap={3}>
              <Player
                sourceLink={url?.data?.sourceLink}
                player={player}
                onLoadedData={(e) => {
                  setPlayer(e.target);
                  e.target.volume = 0.1;
                }}
              />
              <PlayerControls
                player={player}
                onResumeClick={onResumeClick}
                onSliderChange={handleChange}
                sliderValue={value}
              />
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <BottomNavigation
        disabled={!player}
        onCancel={onCancel}
        onContinue={onContinue}
      />
    </>
  );
};

export default SettingsPage;
