import { useModalContext } from "../context/modals/modalContext";
import { Modal, Stack } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(750px, 100%)",
  bgcolor: "background.paper",
  boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
  borderRadius: "20px",
  p: 5,
};

const BaseModal = ({ children, modal }) => {
  const { setActiveModal, activeModal } = useModalContext();
  const handleClose = () => setActiveModal(null);

  return (
    <Modal open={activeModal === modal} onClose={handleClose}>
      <Stack sx={style}>{children}</Stack>
    </Modal>
  );
};

export default BaseModal;
