import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../router/routes";
import { Backdrop, CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";

const NavigateHome = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isDataPresent =
    queryClient.getQueryState([pathname.slice(1)]) || pathname === routes.HOME;

  useEffect(() => {
    let timeoutId;
    if (!isDataPresent) {
      timeoutId = setTimeout(() => navigate(routes.HOME), 700);
    }

    return () => clearTimeout(timeoutId);
  }, [navigate, isDataPresent]);

  return (
    <Backdrop open={!isDataPresent}>
      <CircularProgress />
    </Backdrop>
  );
};

export default NavigateHome;
