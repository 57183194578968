import { styled } from "@mui/system";

export const CircularProgressContainer = styled("div")({
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 1,
  display: "flex",
});

export const Video = styled("video")({
  position: "absolute",
  width: "100%",
  height: "100%",
  objectFit: "scale-down",
});

