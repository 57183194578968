import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import filmImage from "../../assets/images/film.png";
import { CustomInputBase, movieCorrectionFormStyles } from "./MovieCorrectionForm.style";

const MovieCorrectionForm = () => {
  const [film, setFilm] = useState("");

  const onFilmChange = (e) => setFilm(e.target.value);
  const onFilmSubmit = () => null;

  return (
    <Stack sx={movieCorrectionFormStyles.container}>
      <Stack display={{ xs: "none", sm: "flex" }}>
        <img style={movieCorrectionFormStyles.filmImage} src={filmImage} alt="film" />
      </Stack>
      <Stack gap={4}>
        <Stack gap={1}>
          <Typography variant="h3">
            Did we get it wrong? Enter the correct movie title{" "}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            If you didn't find the correct movie title, let us know by entering
            it in the field below. Your input will help us improve our search
            results for everyone. Thank you for helping us make our app better!
          </Typography>
        </Stack>
        <Stack flexDirection="row" gap={1}>
          <CustomInputBase
            value={film}
            onChange={onFilmChange}
            fullWidth
            placeholder="Enter movie title"
          />
          <Button
            onClick={onFilmSubmit}
            disabled={!film}
            sx={movieCorrectionFormStyles.button}
            variant="contained"
          >
            <EastIcon />
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MovieCorrectionForm;
