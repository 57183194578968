import { Box, Button, Stack, Typography } from "@mui/material";
import BaseModal from "./BaseModal";
import { modals } from "../context/modals";
import emojiImage from "./../assets/images/emoji-finger.png";
import { useModalContext } from "../context/modals/modalContext";

const VideoProcessModal = ({ onContinue }) => {
  const { setActiveModal } = useModalContext();

  return (
    <BaseModal modal={modals.VIDEO_PROCESS_MODAL}>
      <Stack gap={3}>
        <Stack
          flexDirection="row"
          gap={5}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <img src={emojiImage} alt="finger emoji" />
          </Box>
          <Stack gap={1}>
            <Typography variant="h4">
              It seems the video processing time will take too long.
            </Typography>
            <Typography variant="body1">
              You can trim the video to get the result faster.
            </Typography>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-end"
          gap={2}
        >
          <Button onClick={onContinue} variant="outlined">
            Continue without trimming
          </Button>
          <Button onClick={() => setActiveModal(null)} variant="contained">
            Trim video
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
};

export default VideoProcessModal;
