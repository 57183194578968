import { styled } from "@mui/system";
import { Slider, Stack, Typography } from "@mui/material";

export const SliderContainer = styled(Stack)({
  maxHeight: "60px",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  flex: 1,
});

export const StyledSlider = styled(Slider)({
  zIndex: 2,
  width: "calc(100% - 20px)",
  height: "35px",
  "& .MuiSlider-rail, & .MuiSlider-track": {
    display: "none",
  },
  "& .MuiSlider-thumb": {
    height: "60px",
    width: "20px",
    borderRadius: "2px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:before": {
      height: "auto",
      width: "auto",
      borderRadius: 0,
      border: "solid black",
      borderWidth: "0 1px 1px 0",
      padding: "5px",
    },
    '&[data-index="0"]': {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      "&:before": {
        transform: "translateX(30%) rotate(135deg) ",
      },
    },
    '&[data-index="1"]': {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      "&:before": {
        transform: "translateX(-30%) rotate(-45deg) ",
      },
    },
  },
});

export const TimeLabelsContainer = styled(Stack)({
  justifyContent: "space-between",
  flexDirection: "row",
  top: "110%",
  width: "100%",
  position: "absolute",
});

export const TimeLabel = styled(Typography)({
  color: "text.secondary",
  fontSize: "8px",
  variant: "caption",
});

export const ThumbnailsContainer = styled(Stack)({
  zIndex: 1,
  flexDirection: "row",
  height: "100%",
  width: "100%",
  position: "absolute",
  borderRadius: 12,
  overflow: "hidden",
});
