import { Button, Container, Stack } from "@mui/material";

const containerHeight = "70px";

const BottomNavigation = ({
  onCancel,
  onContinue,
  disabled,
  actionText = "Continue",
}) => {
  return (
    <Stack height={containerHeight}>
      <Stack
        sx={{ backfaceVisibility: "hidden" }}
        height={containerHeight}
        position="fixed"
        width="100%"
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
        bottom={0}
        py={2}
        zIndex={2}
        bgcolor="common.white"
      >
        <Container>
          <Stack
            width="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              sx={{ width: { xs: "auto", sm: "min(100%, 200px)" } }}
              onClick={onCancel}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{ width: { xs: "auto", sm: "min(100%, 200px)" } }}
              disabled={disabled}
              onClick={onContinue}
              variant="contained"
            >
              {actionText}
            </Button>
          </Stack>
        </Container>
      </Stack>
    </Stack>
  );
};

export default BottomNavigation;
