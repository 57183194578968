import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import Header from "./header";
import DownloadAppAd from "./ads/DownloadAppAd";

export default function DefaultLayout() {
  return (
    <Stack bgcolor="primary.lighter">
      <Stack gap={3} minHeight="100vh">
        <Header />
        <Outlet />
      </Stack>
      <DownloadAppAd />
    </Stack>
  );
}
