import { styled } from "@mui/system";
import { InputBase } from "@mui/material";

export const CustomInputBase = styled(InputBase)(({ theme }) => ({
  backgroundColor: "white",
  padding: "14px 16px",
  borderRadius: 2,
  transition: "outline-color .15s",
  boxShadow: theme.shadows[5],
  "&.Mui-focused": {
    outlineColor: theme.palette.primary.main,
  },
}));

export const movieCorrectionFormStyles = {
  container: {
    padding: 5,
    borderRadius: "20px",
    border: "1px solid",
    borderColor: "primary.main",
    flexDirection: "row",
    gap: 6,
    alignItems: "center",
    justifyContent: "space-between",
  },
  filmImage: {
    objectFit: "scale-down",
  },
  button: {
    borderRadius: "16px",
  },
};
