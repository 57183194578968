import { Stack, Typography } from "@mui/material";
import { getRatingValue } from "../../utils/getRatingValue";
import {
  StyledCard,
  StyledCardMedia,
  StyledChip,
  StyledButton,
  ChipContainer,
  GridContainer
} from "./MovieCard.style";

const MovieCard = ({
  title,
  description,
  posterUrl,
  genres,
  imdbUrl,
  type,
  startYear,
  endYear,
  ratings,
}) => {
  const ratingValue = ratings ? ` · IMDb ${getRatingValue(ratings)}` : '';
  const endYearValue = endYear !== -1 ? ` - ${endYear}` : '';

  const handleButtonClick = () => {
    if (imdbUrl) {
      window.open(imdbUrl, '_blank');
    }
  };

  return (
    <StyledCard>
      <StyledCardMedia
        image={posterUrl}
        title={title}
      />
      <GridContainer>
        <Stack gap={2} mb={3}>
          <Stack>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="caption" color="text.secondary">
              {`${type} · ${startYear}${endYearValue}${ratingValue}`}
            </Typography>
          </Stack>
          <Typography variant="caption">{description}</Typography>
          <ChipContainer>
            {genres.map((genre, index) => (
              <StyledChip key={index} index={1} label={genre} />
            ))}
          </ChipContainer>
        </Stack>
        <StyledButton
          variant="contained"
          size="large"
          fullWidth
          onClick={handleButtonClick}
        >
          Open website
        </StyledButton>
      </GridContainer>
    </StyledCard>
  );
};

export default MovieCard;
