import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const findMovieBySearch = (data) =>
    api.post("/Search", data);

export const getVideoThumbnails = (data) =>
    api.get(`/VideoThumbnails?Url=${data.url}&Width=${data.width}&Height=${data.height}&ThumbnailsCount=${data.count}`);