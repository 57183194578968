import { Navigate, useRoutes } from "react-router-dom";
import routes from "./routes";
import DefaultLayout from "../layouts/default";
import HomePage from "../pages/HomePage";
import SettingsPage from "../pages/SettingsPage";
import ResultsPage from "../pages/ResultsPage";
import NotFoundPage from "../pages/NotFoundPage";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DefaultLayout />,
      children: [
        { path: routes.HOME, element: <HomePage /> },
        { path: routes.SETTINGS, element: <SettingsPage /> },
        { path: routes.RESULTS, element: <ResultsPage /> },
        { path: routes.NOT_FOUND, element: <NotFoundPage /> },
        {
          path: "*",
          element: <Navigate to="/404" replace />,
        },
      ],
    },
  ]);
}
