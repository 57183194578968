import { styled } from "@mui/system";
import { Card, CardMedia, Chip, Button, Grid, Stack } from "@mui/material";
import { pxToRem } from "../../theme/typography";

export const StyledCard = styled(Card)(({ theme }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
}));

export const StyledCardMedia = styled(CardMedia)({
    height: 200,
});

export const StyledChip = styled(Chip)({
    fontSize: pxToRem(12),
});

export const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const ChipContainer = styled(Stack)(({ theme }) => ({
    flexWrap: "wrap",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1),
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    flexDirection: "column",
    justifyContent: "space-between",
    display: "flex",
}));
