import { ThumbnailsContainer } from "./ThumbnailSlider.style";
import { queryKeys, playerControlsConst } from "../../react-query/constants";
import { useQuery } from "react-query";
import getRangeIndices from "../../utils/getRangeIndices";

const ThumbnailSlider = ({ player, sliderRange, onThumbnailClick }) => {

  const { data: results } = useQuery([queryKeys.settings]);

  // Calculate video range based on user selection
  const isThumbnailInRange = (index) => {
    const { startIndex, endIndex } = getRangeIndices(sliderRange, playerControlsConst.thumbnail_number);
    return index >= startIndex && index <= endIndex;
  };

  return (
    <ThumbnailsContainer className="thumbnail-slider">
      {results?.data.thumbnails.map((thumbnail, index) => {
        const { name, bytes } = thumbnail;
        const type = name.split(".").slice(-1);
        const src = `data:image/${type};base64,${bytes}`;

        return (
          <img
            key={index}
            src={src}
            alt={`Thumbnail ${index}`}
            onClick={() => {
              onThumbnailClick((player.duration / playerControlsConst.thumbnail_number) * index);
            }}
            style={{
              width: playerControlsConst.thumbnail_size,
              height: playerControlsConst.thumbnail_size,
              cursor: "pointer",
              filter: isThumbnailInRange(index) ? "none" : "grayscale(100%)",
            }}
          />
        );
      })}
    </ThumbnailsContainer>
  );
};

export default ThumbnailSlider;
