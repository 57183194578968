import { styled } from "@mui/system";
import { Stack } from "@mui/material";

export const ThumbnailsContainer = styled(Stack)({
    alignItems: "center",
    width: "100%",
    height: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
});
