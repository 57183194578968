export const queryKeys = {
  settings: "settings",
  results: "results",
};

export const playerControlsConst = {
  step_width: "50",
  thumbnail_size: "65",
  thumbnail_number: "19",
}
