export default function Chip(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          height: "auto",
        },
        label: {
          padding: "2px 12px",
        },
      },
    },
  };
}
