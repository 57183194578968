import { CircularProgress, Stack } from "@mui/material";
import { CircularProgressContainer, Video } from "./Player.style";

const Player = ({ player, onLoadedData, sourceLink }) => {
  return (
    <Stack
      flex={1}
      position="relative"
      overflow="hidden"
      borderRadius={2}
      bgcolor="#00091F"
      minHeight={{ xs: "385px", md: "500px" }}
    >
      {!player && <CircularProgressContainer><CircularProgress /></CircularProgressContainer>}
      <Video onLoadedData={onLoadedData} preload="auto" src={sourceLink} />
    </Stack>
  );
};

export default Player;
