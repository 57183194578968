import { Container, Stack, Grid, Typography } from "@mui/material";
import MovieCard from "../components/MovieCard/MovieCard";
import MovieCorrectionForm from "../components/MovieCorrectionForm/MovieCorrectionForm";
import { useQuery } from "react-query";
import { queryKeys } from "../react-query/constants";

const ResultsPage = () => {
  const { data: results } = useQuery([queryKeys.results]);

  if (!results) {
    return null;
  }

  return (
    <Container sx={{ mb: 10 }}>
      <Stack gap={6}>
        <Stack gap={1}>
          <Typography variant="h1">We found some matches!</Typography>
          <Typography color="text.secondary" variant="body1">
            Here are your top three results
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          {results?.data.slice(0, 3).map((movie, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <MovieCard {...movie} />
            </Grid>
          ))}
        </Grid>
        <MovieCorrectionForm />
      </Stack>
    </Container>
  );
};

export default ResultsPage;
