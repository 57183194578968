import { Backdrop, Button, Stack, Typography } from "@mui/material";
import { useIsFetching, useQueryClient } from "react-query";
import { queryKeys } from "../../react-query/constants";
import { useQuery } from "react-query";
import Ripple from "../Ripple";

const LoadingScreen = () => {
  const loading = useIsFetching();
  const queryClient = useQueryClient();

  const { isLoading: fetchingSettings } = useQuery([queryKeys.settings]);

  const onCancel = () => queryClient.removeQueries({ fetching: true });

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        "&.MuiBackdrop-root": {
          backgroundColor: (theme) => theme.palette.primary.lighter,
        },
      }}
      open={!!loading}
    >
      <Stack
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        zIndex={1}
        borderRadius="50%"
        bgcolor="white"
        height="165px"
        width="165px"
        position="absolute"
        padding="5px"
      >
        <Typography fontWeight={500} variant="h3">
          {fetchingSettings && loading ? "Analyzing data" : "Searching for a match" }
        </Typography>
      </Stack>
      <Button
        onClick={onCancel}
        size="large"
        sx={{
          zIndex: 1,
          position: "absolute",
          bottom: "10vh",
          width: "min(340px, 100%)",
          borderRadius: "16px",
        }}
        variant="contained"
      >
        Cancel
      </Button>
      <Ripple frequency={10} />
    </Backdrop>
  );
};

export default LoadingScreen;
