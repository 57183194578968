import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, InputBase, Stack, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { queryKeys, playerControlsConst } from "../react-query/constants";
import { getVideoThumbnails } from "../api";
import { useQueryClient } from "react-query";
import routes from "../router/routes";

const HomePage = () => {
  const [url, setUrl] = useState("");
  const onUrlChange = (e) => setUrl(e.target.value);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const onSearchClick = async () => {
    queryClient.clear();

    await queryClient.fetchQuery([queryKeys.settings], () =>
      getVideoThumbnails({
        url,
        width: playerControlsConst.thumbnail_size,
        height: playerControlsConst.thumbnail_size,
        count: playerControlsConst.thumbnail_number,
      })
    );
    navigate(routes.SETTINGS);
  };

  return (
    <Container sx={{ flex: 1, display: "flex" }}>
      <Stack alignItems="center" justifyContent="center" flex={1}>
        <Stack mb={4} gap={1} maxWidth="560px" textAlign="center">
          <Typography variant="h1">
            Instantly identify movies from any clip
          </Typography>
          <Typography color="text.secondary" variant="body1">
            Paste Youtube Shorts, TikTok or Instagram link to find the title of
            a movie or TV show
          </Typography>
          <Stack mt={3} flexDirection="row" gap={1}>
            <InputBase
              value={url}
              onChange={onUrlChange}
              fullWidth
              placeholder="Paste your link"
              sx={{
                backgroundColor: "white",
                padding: "14px 16px",
                borderRadius: 2,
                outline: "1px solid",
                outlineColor: (theme) =>
                  url ? theme.palette.primary.main : "transparent",
                transition: "outline-color .15s",
                boxShadow: (theme) => theme.shadows[5],
                "&.Mui-focused": {
                  outlineColor: (theme) => theme.palette.primary.main,
                },
              }}
            />
            <Button
              onClick={onSearchClick}
              disabled={!url}
              sx={{ borderRadius: "16px" }}
              variant="contained"
            >
              <EastIcon />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default HomePage;
