import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import logo from "../../../assets/icons/logo.svg";
import routes from "../../../router/routes";

export default function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const navigateToHome = () => navigate(routes.HOME);

  const isHomePage = pathname === routes.HOME;

  return (
    <Container sx={{ mt: 2 }}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <img src={logo} alt="tasma" />
          <Typography
            onClick={navigateToHome}
            fontWeight={400}
            variant="h4"
            color="primary.main"
            sx={{ cursor: "pointer" }}
          >
            tasma
          </Typography>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Button
            sx={{ width: { xs: "auto", sm: "170px" } }}
            variant={isHomePage ? "contained" : "outlined"}
          >
            Download app
          </Button>
          {pathname === routes.RESULTS && (
            <>
              {matches ? (
                <IconButton size="large" onClick={navigateToHome}>
                  <SearchOutlinedIcon />
                </IconButton>
              ) : (
                <Button
                  sx={{ width: "170px" }}
                  onClick={navigateToHome}
                  variant="contained"
                >
                  New search
                </Button>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}
