import { useEffect, useState } from "react";
import { playerControlsConst } from "../../react-query/constants";
import { IconButton, Skeleton, Stack } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ThumbnailSlider from "../ThumbnailSlider/ThumbnailSlider";
import convertSecondsToTime from "../../utils/convertSecondsToTime";
import {
  SliderContainer,
  StyledSlider,
  TimeLabelsContainer,
  TimeLabel,
  ThumbnailsContainer,
} from "./PlayerControls.style";

const PlayerControls = ({
  player,
  onResumeClick,
  sliderValue,
  onSliderChange,
}) => {
  const [timeStep, setTimeStep] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    const handleOnWindowChange = () => {
      if (player) {
        const playerWidth = player.getBoundingClientRect().width;
        let maxSteps = Math.floor(playerWidth / playerControlsConst.step_width);
        const fixedStep = 0.1;
        let currentStep = 1;

        while (player.duration / currentStep > maxSteps) {
          currentStep++;
        }

        setTimeStep(currentStep);
        setStep(fixedStep);      }
    };

    if (player) {
      handleOnWindowChange();
    }

    window.addEventListener("resize", handleOnWindowChange);

    return () => window.removeEventListener("resize", handleOnWindowChange);
  }, [player]);

  return (
    <Stack flexDirection="row" gap={2} alignItems="center">
      <Stack
        height="100%"
        borderRadius={1}
        bgcolor="common.white"
        alignItems="center"
        justifyContent="center"
        px="4px"
      >
        <IconButton disabled={!player} onClick={onResumeClick}>
          <PlayArrowIcon />
        </IconButton>
      </Stack>
      <SliderContainer>
        <StyledSlider
          disabled={!player}
          value={sliderValue}
          onChange={onSliderChange}
          step={step}
        />
        <TimeLabelsContainer>
          {[
            ...new Array(
              timeStep ? Math.floor(player.duration / timeStep) : 0
            ),
          ].map((_, index) => (
            <TimeLabel key={index} variant="caption">
              {convertSecondsToTime((index + 1) * timeStep).slice(3)}
            </TimeLabel>
          ))}
        </TimeLabelsContainer>
        <ThumbnailsContainer>
          {timeStep ? (
            <ThumbnailSlider
              player={player}
              sliderRange={sliderValue}
              onThumbnailClick={(time) => {
                if (player) {
                  player.currentTime = time;
                }
              }}
            />
          ) : (
            <Skeleton height="100%" width="100%" />
          )}
        </ThumbnailsContainer>
      </SliderContainer>
    </Stack>
  );
};

export default PlayerControls;
